import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Web3, { MetaMaskProvider } from 'web3';
import './App.css';
import { Web3APISpec } from 'web3-types/src/web3_api_types';
import TOKEN_ABI from './contract-abi.json';
import { Button, Radio, Select, Input, Typography, Space, Divider, Table } from 'antd';


declare global {
  interface Window {
    ethereum: MetaMaskProvider<Web3APISpec>;
  }
}

enum Currency {
  eth = 'eth',
  add = 'add'
}

enum TransactionMode {
  withdraw = 'withdraw',
  deposit = 'deposit'
}


const TOKEN_CONTRACT_ADDRESS = '0x24C5A43A0D5AfD8D4a1Afd9D262AF6B0D43a5fA9';

interface UserInfo {
  readonly id: number;
  readonly signature: string;
  readonly ethBalance: number;
  readonly addBalance: number;
  readonly createdAt: string;
  readonly updatedAt: string;
}

interface TransactionInfo {
  readonly key: string;
  readonly type: TransactionMode;
  readonly hash: string;
  readonly coin: Currency;
  readonly amount: number;
  readonly createdAt: string;
}

function App() {
  const [web3, setWeb3] = useState<Web3 | null>(null);
  const [account, setAccount] = useState<string | null>(null);
  const [amount, setAmount] = useState<string>('');
  const [mode, setMode] = useState<TransactionMode>(TransactionMode.deposit);
  const [currency, setCurrency] = useState<Currency>(Currency.eth);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [amountError, setAmountError] = useState<string | null>(null);
  const [transactions, setTransactions] = useState<TransactionInfo[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.location.origin}/api/v1/users/me`);
        if (response.status < 400) {
          setUserInfo(response.data);
          await connectMetaMask()
          startShortPulling()
        }
      } catch {
      }
    }
    fetchData();
  }, []);

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const accounts = await connectMetaMask()

        const response = await axios.post(`${window.location.origin}/api/v1/users/login`, {
          walletAddress: accounts[0],
        });
        setUserInfo(response.data);
        startShortPulling()

      } catch (error) {
        console.error('Connection:', error);
      }
    } else {
      alert('Please install Metamask!');
    }
  };

  const connectMetaMask = async () => {
    if (window.ethereum) {
      try {
        const web3Instance = new Web3(window.ethereum);
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await web3Instance.eth.getAccounts();
        setWeb3(web3Instance);
        setAccount(accounts[0]);

        return accounts
      } catch (error) {
        console.error('Connection:', error);
      }
    }

    return []
  }

  const startShortPulling = () => {
    setInterval(
      async () => {
        const userResponse = await axios.get(`${window.location.origin}/api/v1/users/me`);
        setUserInfo(userResponse.data);

        const transactionResponse = await axios.get(
          `${window.location.origin}/api/v1/users/me/transactions`,
        );
        setTransactions(transactionResponse.data);
      },
      5000, // 5 seconds
    )
  }

  const handleAction = async () => {
    if (currency !== Currency.eth) {
      return
    }
    
    setAmount('');

    if (mode === TransactionMode.deposit) {
      await depositEth()
    } else  if (mode === TransactionMode.withdraw ) {
      await withdrawEth()
    }
  };

  const depositEth = async () => {
    try {
      if (!web3 || !account) {
        alert('Please, connect wallet!');
        return;
      }

      const tokenContract = new web3.eth.Contract(TOKEN_ABI, TOKEN_CONTRACT_ADDRESS);

      const amountInWei = web3.utils.toWei(amount, 'ether');
      console.log(amountInWei)

      await tokenContract.methods.deposit().send({
        from: account,
        value: amountInWei,
      });
    } catch(err) {
      console.log(err)
    }

  };

  const withdrawEth = async () => {
    throw new Error('Not implemented');
  };

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text: string) => <Typography.Text>{
        text === TransactionMode.deposit ? 'Deposit' : 'Withdraw'
      }</Typography.Text>,
    },
    {
      title: 'Coin',
      dataIndex: 'coin',
      key: 'coin',
      render: (text: string) => <Typography.Text>{text.toUpperCase()}</Typography.Text>,
    },
    {
      title: 'Hash',
      dataIndex: 'hash',
      key: 'hash',
      render: (text: string) => (
        <Typography.Text copyable>{text}</Typography.Text>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text: string) => <Typography.Text>{text}</Typography.Text>,
    },
  ];

  return (
    <div className="app">
      <header className="app-header">
        <Typography.Title
          level={1}
          style={{ color: '#1890ff', textAlign: 'center', marginBottom: '24px' }}
        >
          Metamask Transaction
        </Typography.Title>

        <Space
          direction="vertical"
          size="large"
          style={{ width: '100%', maxWidth: '400px', margin: '0 auto' }}
        >
          {!account && (
            <Button
              type="primary"
              size={'large'}
              onClick={connectWallet}
              style={{ width: '100%', marginTop: '20px' }}
            >
              Connect Metamask
            </Button>
          )}

          {account && (
            <div className="account-info">
              <Typography.Text strong className="account-text" copyable={{ text: account }}>
                Connected: {`${account.slice(0, 8)}...${account.slice(account.length - 8)}`}
              </Typography.Text>
              <Divider />
              <div className="balance">
                <Typography.Text strong className="balance-text">ETH Balance: </Typography.Text>
                <Typography.Text className="balance-text">{userInfo?.ethBalance ?? 0}</Typography.Text>
              </div>
              <div className="balance">
                <Typography.Text strong className="balance-text">ADD Balance: </Typography.Text>
                <Typography.Text className="balance-text">{userInfo?.addBalance ?? 0}</Typography.Text>
              </div>
            </div>
          )}

          <div className="mode-selection">
            <Typography.Text strong className="mode-text">Transaction Type:</Typography.Text>
            <Radio.Group
              onChange={(e) => setMode(e.target.value)}
              value={mode}
              style={{ marginLeft: '20px' }}
            >
              <Radio
                value={TransactionMode.deposit}
                className="mode-text"
              >Deposit</Radio>
              <Radio
                disabled
                value={TransactionMode.withdraw}
                className="mode-text"
              >Withdraw</Radio>
            </Radio.Group>
          </div>

          <div className="currency-selection" >
            <Typography.Text strong className="currency-text">Select Currency: </Typography.Text>
            <Select
              value={currency}
              size={'large'}
              onChange={(value: Currency) => setCurrency(value)}
              style={{ width: '100%', marginTop: '10px' }}
            >
              <Select.Option value={Currency.eth}>ETH</Select.Option>
              <Select.Option disabled value={Currency.add} >ADD</Select.Option>
            </Select>
          </div>

          <Input
            placeholder="Amount"
            size={'large'}
            value={amount}
            onChange={(e) => {
              const value = Number(e.target.value)
              if (isNaN(value) || value < 0) {
                setAmountError('Please enter a valid positive number.');
              } else {
                setAmountError(null);
              }
              setAmount((e.target.value || '').toString());
            }}
            style={{ marginTop: '10px' }}
            status={amountError ? 'error' : undefined} // Устанавливаем статус ошибки
          />
          {amountError}

          <Button
            type="primary"
            size={'large'}
            onClick={handleAction}
            disabled={!account || !!amountError || amount === ''}
            style={{ width: '100%', marginTop: '10px' }}
          >
            Confirm {mode === TransactionMode.deposit ? 'Deposit' : 'Withdraw'}
          </Button>
        </Space>
      </header>

      <div className="transaction-history">
        <Divider/>
        <Typography.Title level={3} style={{ textAlign: 'center', marginBottom: '24px' }}>
          Transaction History
        </Typography.Title>
        <Table
          columns={columns}
          dataSource={transactions}
          pagination={{ pageSize: 5 }}
          style={{ width: '100%' }}
        />
      </div>

    </div>
  );
};

export default App;
